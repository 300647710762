import React from 'react';

import { scroller } from 'react-scroll';
import {
  wrap, titleWrap, titlePc, moreInfo,
} from './style.module.css';
import DOWN_ARROW from '../../../images/icon_down_arrow.png';
import HERO_TITLE_1_PC from '../../../images/main/hero_title_1_pc.svg';
import HERO_TITLE_2_PC from '../../../images/main/hero_title_2_pc.svg';

function MainHero() {
  const onClickScrollHandler = () => {
    scroller.scrollTo('managementAmountSection', {
      duration: 1500,
      delay: 0,
      smooth: 'easeInOutQuart',
      ignoreCancelEvents: false,
    });
  };

  return (
    <section className={wrap}>
      <article className={titleWrap}>
        <img
          className={titlePc}
          src={HERO_TITLE_1_PC}
          alt="EMPOWERING PEOPLE WITH DATA"
        />
        <img
          className={titlePc}
          src={HERO_TITLE_2_PC}
          alt="TO MAKE A BETTER LIFE"
        />
      </article>
      <div
        className={moreInfo}
        onClick={onClickScrollHandler}
      >
        <p>더 알아 보실래요?</p>
        <img src={DOWN_ARROW} alt="스크롤 다운 안내 아이콘" />
      </div>
    </section>
  );
}

export default MainHero;
