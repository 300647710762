import React from 'react';

import { Link } from 'gatsby';
import {
  wrap, subTitle, title, contents, button, scrollText,
} from './style.module.css';
import LottieView from '../../../components/Lottie';
import HOW_WE_WORK from '../../../images/lottie/how_we_work.json';
import M_HOW_WE_WORK from '../../../images/lottie/m_how_we_work.json';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import sendGAEvent from '../../../utils/event/core';
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '../../../utils/event/data';
import DATA from '../../../data';

const Impact = () => {
  const browserWidth = useBrowserWidth();

  return (
    <section className={wrap}>
      <p className={subTitle}>
        OUR VALUE
      </p>
      <h1 className={title}>
        우리는 오로지
        <br />
        고객 임팩트에 몰입합니다.
      </h1>
      <p className={contents}>
        능동적이고 책임감 있는 전문가들이 함께 성장하며
        <br />
        세상에 선한 영향력을 발휘합니다.
      </p>
      <Link
        to="/culture"
        onClick={() => sendGAEvent(
          GA_CATEGORY.BTN.CULTURE,
          GA_ACTION.LINK('조직문화'),
          GA_LABEL.NONE,
        )}
        className={button}
      >
        조직문화 보기
      </Link>
      <div className={scrollText}>
        <LottieView
          lottieId="impact_scroll"
          autoplay
          loop
          animationData={
            browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET)
              ? M_HOW_WE_WORK
              : HOW_WE_WORK
          }
        />
      </div>
    </section>
  );
};

export default Impact;
