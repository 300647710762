import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import {
  wrap, row, lottie, info,
} from './style.module.css';
import Info from '../../../components/Info';
import LottieView from '../../../components/Lottie';

import DOWNLOAD from '../../../images/lottie/download.json';
import M_DOWNLOAD from '../../../images/lottie/m_download.json';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import DATA from '../../../data';

const Download = () => {
  const [isPlay, setIsPlay] = useState(false);
  const browserWidth = useBrowserWidth();

  useEffect(() => {
    const actionEvent = ({ detail }) => {
      if (detail.target.id === 'download') {
        setIsPlay(true);
      }
    };

    window.addEventListener('sal:in', actionEvent);
    return () => window.removeEventListener('sal:in', actionEvent);
  }, []);

  return (
    <section className={wrap}>
      <div className="container-md">
        <div className={classNames('row', row)}>
          <div
            id="download"
            className={classNames('col-md-6', lottie)}
            data-sal="fade"
            data-sal-duration="0"
            data-sal-delay="0"
            data-sal-easing="ease"
          >
            <LottieView
              lottieId="download_graph"
              autoplay={false}
              loop={false}
              isPlay={isPlay}
              animationData={
                browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET) ? M_DOWNLOAD : DOWNLOAD
              }
            />
          </div>
          <div className={classNames('col-md-6', info)}>
            <Info
              title="뱅크샐러드<br class='onlyPC'/> 누적 다운로드"
              subContent="2023년 기준"
            >
              범국민서비스로 도약
              <br />
              1년간 두 배에 달하는 양적 성장 달성
            </Info>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Download;
