import React from 'react';

import { Link } from 'gatsby';
import Swiper from 'react-id-swiper';
import classNames from 'classnames';
import {
  wrap, contentsLayout, button, images, imgItem,
} from './style.module.css';
import Info from '../../../components/Info';
import IMG_1 from '../../../images/main/our_culture/1.png';
import IMG_2 from '../../../images/main/our_culture/2.png';
import IMG_3 from '../../../images/main/our_culture/3.png';
import IMG_M_1 from '../../../images/main/our_culture/m_1.png';
import IMG_M_2 from '../../../images/main/our_culture/m_2.png';
import IMG_M_3 from '../../../images/main/our_culture/m_3.png';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import sendGAEvent from '../../../utils/event/core';
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '../../../utils/event/data';
import DATA from '../../../data';

const Culture = () => {
  const browserWidth = useBrowserWidth();
  const List = [{
    urlPC: IMG_1,
    urlMobile: IMG_M_1,
    name: '회사복지 업무집중 장비',
  }, {
    urlPC: IMG_2,
    urlMobile: IMG_M_2,
    name: '회사복지 휴게공간',
  }, {
    urlPC: IMG_3,
    urlMobile: IMG_M_3,
    name: '회사복지 카페테리아',
  }];

  const params = {
    slidesPerView: 'auto',
    speed: 1000,
    loop: !browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET),
    autoplay: browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET)
      ? {}
      : {
        delay: 2500,
        disableOnInteraction: false,
      },
    spaceBetween: browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET) ? 15 : 30,
    centeredSlides: true,
  };
  return (
    <section className={wrap}>
      <div className="container-md">
        <Info
          subTitle="Our culture"
          title="우리는 고객에 몰입할 수 있는<br/>최적의 업무환경을 지원합니다."
          isFullWidth
        >
          업무 효율을 위한 최고급 장비 지원,
          <br />
          점심 식사 제공 및 유연근무제 운영 등
          <br />
          개인의 능률 향상을 위해 아낌없이 지원합니다.
        </Info>
      </div>
      <div className={contentsLayout}>
        <div className="container-md">
          <Link
            className={button}
            onClick={() => sendGAEvent(
              GA_CATEGORY.BTN.WELFARE,
              GA_ACTION.LINK('라이프 & 피플'),
              GA_LABEL.NONE,
            )}
            to="/culture"
          >
            복지제도 보기
          </Link>
        </div>
        <div className={classNames(images, 'onlyPC')}>
          <Swiper {...params}>
            {
              List.map((item) => (
                <img
                  key={item.name}
                  src={item.urlPC}
                  alt={item.name}
                  className={imgItem}
                />
              ))
            }
          </Swiper>
        </div>
        <div className={classNames(images, 'onlyMobile')}>
          <Swiper {...params}>
            {
              List.map((item) => (
                <img
                  key={item.name}
                  src={item.urlMobile}
                  alt={item.name}
                  className={imgItem}
                />
              ))
            }
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Culture;
