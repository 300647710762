import React from 'react';

import MainHero from './MainHero';
import Download from './Download';
import InvestAmount from './InvestAmount';
import Impact from './Impact';
import Culture from './Culture';

function Main({ ...props }) {
  return (
    <div
      {...props}
    >
      <MainHero />
      <Download />
      <InvestAmount />
      <Impact />
      <Culture />
    </div>
  );
}

export default Main;
