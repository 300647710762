import React, { useEffect, useState } from 'react';

import * as classnames from 'classnames';
import { wrap, info, lottie } from './style.module.css';

import LOTTIE_AMOUNT from '../../../images/lottie/invest.json';
import M_LOTTIE_AMOUNT from '../../../images/lottie/m_invest.json';
import LottieView from '../../../components/Lottie';
import Info from '../../../components/Info';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import DATA from '../../../data';

const InvestAmount = () => {
  const [isPlay, setIsPlay] = useState(false);
  const browserWidth = useBrowserWidth();

  useEffect(() => {
    const actionEvent = ({ detail }) => {
      if (detail.target.id === 'invest_amount') {
        setIsPlay(true);
      }
    };

    window.addEventListener('sal:in', actionEvent);
    return () => window.removeEventListener('sal:in', actionEvent);
  }, []);

  return (
    <section className={wrap}>
      <div className="container-md">
        <div className="row">
          <div className={classnames('col-md-6', info)}>
            <Info
              title="뱅크샐러드<br class='onlyPC'/> 누적 투자액"
              subContent="2022년 7월 기준"
            >
              PFM 서비스를 넘어 데이터 회사로서의
              <br />
              본질적인 가치를 입증
            </Info>
          </div>
          <div
            id="invest_amount"
            className={classnames('col-md-6', lottie)}
            data-sal="fade"
            data-sal-duration="0"
            data-sal-delay="0"
            data-sal-easing="ease"
          >
            <LottieView
              lottieId="invest_amount"
              autoplay={false}
              loop={false}
              isPlay={isPlay}
              animationData={
                browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET) ? M_LOTTIE_AMOUNT : LOTTIE_AMOUNT
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestAmount;
